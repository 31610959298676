<template>
  <div class="rightSide">
    <div @click="toCallHelper">
      <img src="../../../assets/image/freeSoftware/farSet.png" alt="">
      <div>远程安装</div>
    </div>
    <div @click="toOrder">
      <img src="../../../assets/image/freeSoftware/farOrder.png" alt="">
      <div>远程订单</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "helper.vue",
  computed: {
    ...mapGetters(["isLogin"]),
  },
  methods:{
    toCallHelper(){
      if(!this.isLogin){
        this.$login()
        return
      }
      this.$paySoftwarePop()
    },
    toOrder(){
      if(!this.isLogin){
        this.$login()
        return
      }
      const {href} = this.$router.resolve({ path: "/software/softwareOrder"});
      window.open(href, '_blank')
    }
  }
}
</script>

<style scoped>
.rightSide{
  position: fixed;
  right:0.73rem;
  top:40%;
  height: 0.24rem;
  color: rgba(56, 56, 56, 1);
  font-size: 0.16rem;
  font-weight: 500;
  z-index: 10;
}
.rightSide>div{
  margin-bottom:0.12rem;
  text-align: center;
  cursor: pointer;
  width: 1rem;
  height: 1.18rem;
  padding: 0.12rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 4px rgba(238, 148, 84, 0.25);
  border-radius: 0.08rem;
}
</style>